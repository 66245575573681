import * as React from "react"
import styled from "styled-components"
import { H1 } from "../components/styles/Primitives"
import { base } from "../components/styles/ColorStyles"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectCard from "../components/project/ProjectCard"

const Writing = () => (
  <Layout>
    <Seo title="Writing | Atharva Patil" />
    <section>
      <Wrapper>
        <H1>Writing</H1>
      </Wrapper>
    </section>
    <WritingList>
      <ProjectCard
          Title="Powering job search with data-driven insights" 
          Subtitle="How I used google analytics to optimize my Product Designer job search. UX Collective" 
          Link="https://bootcamp.uxdesign.cc/powering-job-search-with-data-driven-insights-4c4b0faccda5" 
      />
      <ProjectCard
          Title="Sit Straight! — Encouraging a good sitting posture" 
          Subtitle="Case study of a posture classifier that blurs out Youtube videos if users don’t sit straight. UX Planet" 
          Link="https://uxplanet.org/sit-straight-encouraging-a-good-sitting-posture-cce1fbe038c0" 
      />
       <ProjectCard
          Title="Don’t touch your face" 
          Subtitle="Case study on design & development of machine learning classifier to issue a warning. The startup" 
          Link="https://medium.com/swlh/dont-touch-your-face-9e59b8641b7b" 
      />
       <ProjectCard
          Title="Designing Payments for Bots" 
          Subtitle="Design challeneges with digital payment ecosystem before UPI in India. UX in India" 
          Link="https://medium.com/ux-for-india/designing-payements-for-bots-4ecbc22d07ef" 
      />
       <ProjectCard
          Title="Design teardown: Reimagining email" 
          Subtitle="Thoughts on how to create a new of email client to achieve an inbox zero. Invision blog" 
          Link="https://www.invisionapp.com/inside-design/reimagining-email/" 
      />
       <ProjectCard
          Title="My first year as a designer in a startup" 
          Subtitle="Reflections on the first year of my career as a Product Designer in an early stage startup. UX in India" 
          Link="https://medium.com/ux-for-india/my-first-year-as-a-designer-in-a-startup-dc39e996a499" 
      />
    </WritingList>
  </Layout>
) 

export default Writing

const Wrapper = styled.div`
  padding: 7rem 1rem 4rem 1rem;
  margin: 0 auto;
  border-bottom: 1px dashed ${base.border};
`

const WritingList = styled.section`
  padding: 3rem 0rem;
  
`